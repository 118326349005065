<template>
	<Modal name="appointments-history">
		<template v-slot:header>
			<div class="d-flex">
				<button v-if="stack.length > 1" id="back-mobile" class="btn btn-gray mobile" v-on:click="popStack" type="button"><i class="fas fa-chevron-left"></i></button>
				<h1 class="title-search">{{ title }}</h1>
				<div class="title-search desktop" v-if="currentMode == 'year'">
					<Popper
						class="dropdown d-block workers-dropdown"
						trigger="clickToToggle"
						:options="{ placement: 'bottom' }"
						:visible-arrow="false"
						ref="yearsPopper"
					>
						<div class="dropdown-menu" @click="$refs.yearsPopper.doClose()">
							<div class="dropdown-choices">
								<button v-for="year in allYears" class="dropdown-item" @click="$refs.yearSelector.textContent = 1900 + year.getYear(); switchTo( 'year', year )">{{ 1900 + year.getYear() }}</button>
							</div>
						</div>
						<button
							slot="reference"
							ref="yearSelector"
							aria-expanded="false"
							aria-haspopup="true"
							class="btn btn-secondary dropdown-toggle"
							data-flip="false"
							data-toggle="dropdown"
						>{{ 1900 + allYears[ allYears.length - 1 ].getYear() }}</button>
					</Popper>
				</div>
			</div>
		</template>
		<template v-slot:content>
			<table>
				<template v-if="currentMode === 'day' || typeof currentMode === 'object'">
					<thead>
						<th>{{ $t('common.worksch') }}</th>
						<th>{{ $t('common.worker') }}</th>
						<th>{{ $t('common.customer') }}</th>
						<th>{{ $t('common.services') }}</th>
					</thead>
					<tbody>
						<tr v-for="row in rows" :key="row.id || row.date" :class="{ disabled: row.disabled, canceled: (row.status || '').match(/cancel/gi) }">
							<td>{{ dayjs( row.date ).format( "dddd DD MMM - HH:mm" ) }}</td>
							<td>
								<component
									:is="isForeignSalon ? 'span' : 'router-link'"
									v-if="row.worker"
									:to="'/worker?id=' + row.worker.id"
								>
									<span @click="!isForeignSalon && $emit('close')">
										{{ row.worker.name }}
									</span>
								</component>
								<i v-else>[{{ $tc('common.accdel') }}]</i>
							</td>
							<td>
								<component
									:is="isForeignSalon || !!row.user.unregistration ? 'span' : 'router-link'"
									v-if="row.user"
									:to="'/user?id=' + row.user.id"
								>
									<i v-if="!!row.user.unregistration">[{{ $t('common.accdel') }}]</i>
									<span v-else @click="!isForeignSalon && $emit('close')">
										{{ row.user.lname + " " + row.user.fname }}
									</span>
								</component>
								<i v-else>{{ $tc('common.anonymous') }}</i>
							</td>
							<td class="service"><i>{{ row.title }}</i></td>
						</tr>
					</tbody>
				</template>
				<template v-else-if="currentMode === 'month' || currentMode === 'year'">
					<thead>
						<th>{{ $t('common.date') }}</th>
						<th>{{ $t('common.count') }}</th>
					</thead>
					<tbody>
						<tr v-for="row in getRows" :key="row.dateString" @click="row.disabled || row.nolink ? null : switchTo( currentMode === 'year' ? 'month' : 'day', row.date )" :class="{ link: true, disabled: row.disabled, nolink: row.nolink }">
							<td :class="{ link: true, disabled: row.disabled }">{{ row.dateString }}</td>
							<td>{{ row.disabled ? '' : row.count }}</td>
						</tr>
					</tbody>
				</template>
			</table>
		</template>
		<template v-slot:buttons>
			<Popper
				v-if="currentMode == 'year'"
				class="dropdown d-block workers-dropdown mobile"
				trigger="clickToToggle"
				:options="{ placement: 'bottom' }"
				:visible-arrow="false"
				ref="yearsPopperMobile"
			>
				<div class="dropdown-menu" @click="$refs.yearsPopperMobile.doClose()">
					<div class="dropdown-choices">
						<button v-for="year in allYears" class="dropdown-item" @click="$refs.yearSelectorMobile.textContent = 1900 + year.getYear(); switchTo( 'year', year )">{{ 1900 + year.getYear() }}</button>
					</div>
				</div>
				<button
					slot="reference"
					ref="yearSelectorMobile"
					aria-expanded="false"
					aria-haspopup="true"
					class="btn btn-secondary dropdown-toggle"
					data-flip="false"
					data-toggle="dropdown"
				>{{ 1900 + allYears[ allYears.length - 1 ].getYear() }}</button>
			</Popper>
			<div class="back-buttons">
				<button v-if="stack.length > 1" id="back" class="btn btn-gray desktop" v-on:click="popStack" type="button">{{ $t("common.return") }}</button>
				<button id="close" class="btn btn-gray" v-on:click="$emit('close')" type="button">{{ $t("common.close") }}</button>
			</div>
		</template>
	</Modal>
</template>

<script>
	import dayjs from 'dayjs'
	import Popper from 'vue-popperjs'
	import Modal from '../components/Modal.vue'

	export default {
		props: [
			"mode",
			"salonid"
		],
		components: {
			Modal,
			Popper
		},
		data() {
			return {
				dayjs: dayjs,
				currentMode: "",
				showBackButton: false,
				rows: [],
				stack: [],
				titleMode: "",
				loading: null
			}
		},
		computed: {
			allYears() {
				let ret = []
				let date = new Date( this.$store.state.salon.subscription_date )
				while ( date.getYear() <= new Date().getYear() ) {
					ret.push( date )
					date = date.addYears( 1 )
				}
				return ret
			},
			getRows() {
				return this.rows.sort( ( a, b ) => a.dayIdx - b.dayIdx );
			},
			title() {
				if ( Array.isArray( this.titleMode ) ) {
					return  this.$d( this.titleMode[1], this.titleMode[2] )
				}
				return this.$t( this.titleMode )
			},
			isForeignSalon() {
				return this.salonid >= 0 && this.salonid != this.$store.state.salon.id
			}
		},
		methods: {
			update( mode, start ) {
				this.currentMode = mode;
				this.stack.push({ mode: mode, date: start });
				this.rows = [];
				let now = new Date();
				let schedule = this.$store.getters.getSchedules().sort( ( a, b ) => a.id - b.id )
				schedule = schedule.map( sched => !( sched.morningop == "0" && sched.morningclos == "0" && sched.afternop == "0" && sched.afternclos == "0" ) )
				if ( typeof mode === "object" ) { // For current day, Index already has all appointments data, so it passes them to us here
					this.titleMode = "titles.history.today"
					this.rows = mode;
				} else if ( mode === "day" ) {
					let today = new Date()
					today.setMilliseconds( 0 )
					today.setSeconds( 0 )
					today.setMinutes( 0 )
					today.setHours( 0 )
					if ( start.getTime() === today.getTime() ) {
						this.titleMode = "titles.history.today"
					} else {
						this.titleMode = [ "titles.history.basic", start, "longonlydate" ]
					}

					let end = new Date( start ).addDays( 1 );
					this.$api.appointments.get({ salonid: this.salonid, "{sort}": "asc:date", "date[gte]": dayjs(start).format( "YYYY-MM-DD" ), "date[lt]": dayjs(end).format( "YYYY-MM-DD" ), "status[~*]": "finished" }).then( async response => {
						await Promise.all( response.data.map( async row => {
							await this.$store.getters.getUser( row.userid ).then( resp => {
								if ( resp ) {
									row.user = resp.data[0]
								}
							}).catch( error => {
								console.log( error )
							})
							row.worker = this.$store.getters.getWorker( row.workers[0] )
						}))
						this.rows = response.data
					}).catch( error => console.log( error ) )
				} else {
					start.setDate( 1 );
					let end = new Date( start ).addDays( 1 );
					let dayIdx = 0;
					let format = "dddd DD MMMM YYYY";

					let add = function( date ) {
						return date.addDays( 1 );
					}
					if ( mode === "month" ) {
						let thismonth = new Date()
						thismonth.setMilliseconds( 0 )
						thismonth.setSeconds( 0 )
						thismonth.setMinutes( 0 )
						thismonth.setHours( 0 )
						thismonth.setDate( 1 )
						if ( start.getTime() === thismonth.getTime() ) {
							this.titleMode = "titles.history.month"
						} else {
							this.titleMode = [ "titles.history.basic", start, "monthyear" ]
						}
						now = new Date( start ).addMonths( 1 );
					} else if ( mode === "year" ) {
						this.titleMode = "titles.history.year"

						start.setMonth( 0 );
						end = new Date( start ).addMonths( 1 );
						format = "MMMM YYYY";
						add = function( date ) {
							return date.addMonths( 1 );
						}

						if ( start.getYear() !== now.getYear() ) {
							now.setMilliseconds( 0 )
							now.setSeconds( 0 )
							now.setMinutes( 0 )
							now.setHours( 0 )
							now.setDate( 1 )
							now.setMonth( 0 )
						}
					}


					let promises = []
					while ( dayjs( start ).isBefore( dayjs( now ) ) ) {
						const date = new Date( start );
						const endDate = dayjs( end );
						const dIdx = dayIdx;
						let prom = this.$api.appointments.count({ salonid: this.salonid, "date[gte]": dayjs(date).format( "YYYY-MM-DD" ), "date[lt]": endDate.format( "YYYY-MM-DD" ), "status[~*]": "finished" }).then( response => {
							let ret = response.data[0]
							let row = { dayIdx: dIdx, date: date, dateString: dayjs(date).format( format ), count: ret.count }
							if ( mode === "month" && !schedule[( date.getDay() + 6 ) % 7] ) {
								row.disabled = true
							}
							if ( row.count == 0 ) {
								row.nolink = true
							}
							this.rows.push( row )
						}).catch( error => console.log( error ) )
						promises.push( prom )
						dayIdx++;
						start = add( start );
						end = add( end );
					}
					this.loading = Promise.all( promises )
				}
			},
			switchTo( mode, date ) {
				if ( this.loading === null ) {
					this.update( mode, date )
				} else {
					this.loading.then( () => this.update( mode, date ) )
				}
			},
			popStack() {
				const back = this.stack.splice( this.stack.length - 2, 2 )[0];
				if ( this.loading === null ) {
					this.update( back.mode, back.date )
				} else {
					this.loading.then( () => this.update( back.mode, back.date ) )
				}
			}
		},
		mounted() {
			let start = new Date();
			start.setMilliseconds( 0 );
			start.setSeconds( 0 );
			start.setMinutes( 0 );
			start.setHours( 0 );
			this.update( this.mode, start );
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/appointmentshistory.scss"/>
