export default {
	install( thiz, callback ) {
		function carousel_setup_interval( carousel ) {
			var interval = parseInt( carousel.attr( "data-interval" ) );
			if ( interval != null && interval > 0 ) {
				var id = setInterval( function() {
					carousel.find('.carousel-control-next').click();
				}, interval );
				carousel.data( "interval-id", id );
			}
		}

		$(thiz.$el).find('.carousel').each( function() {
			carousel_setup_interval( $(this) );
		});

		function carousel_reset( carousel, inner ) {
			var intervalid = carousel.data( "interval-id" );
			var timerid = carousel.data( "timer-id" );
			if ( timerid ) {
				carousel.removeData( "timer-id" );
				var first = carousel.data("first");
				clearTimeout( timerid );
				first.detach();
				inner.append( first );
				inner.addClass( "switching" );
				carousel.find( ".carousel-item" ).css( "transform", "translateX(0%)" );
				first[0].offsetHeight;
				inner.removeClass( "switching" );
			}
			if ( intervalid ) {
				clearTimeout( intervalid );
				clearInterval( intervalid );
				// Wait 1.5 second before restarting carousel, let people read :)
				var id = setTimeout( function() {
					carousel_setup_interval( carousel );
				}, 2000 );
				carousel.data( "interval-id", id );
			}
		}

		$(thiz.$el).find('.carousel-control-prev').click( function() {
			var carousels = $(this).attr("data-targets");
			carousels.split( " " ).forEach( function( carousel ) {
				carousel = $( thiz.$el ).find( carousel );
				var inner = carousel.find( ".carousel-inner" );
				carousel_reset( carousel, inner );

				var index = carousel.attr( "data-index" );
				var max_index = carousel.attr( "data-max-index" ) - 1;
				if ( max_index > 0 ) {
					var children = carousel.find(".carousel-inner .carousel-item");
					var last = $( children[max_index] );
					if ( index > 0 ) {
						index--;
					} else {
						index = max_index;
					}
					carousel.attr( "data-index", index );
					inner.addClass( "switching" );
					carousel.find( ".carousel-item" ).css( "transform", "translateX(-100%)" );
					last.detach();
					inner.prepend( last );
					last[0].offsetHeight;
					inner.removeClass( "switching" );
					carousel.find( ".carousel-item" ).css( "transform", "translateX(0%)" );
					carousel.find( ".carousel-indicators li" ).removeClass( "active" );
					$( carousel.find( ".carousel-indicators li" )[index] ).addClass( "active" );
					var children = carousel.find(".carousel-inner .carousel-item");
					$( children ).removeClass( "active" );
					$( children[ 1 ] ).addClass( "active" );
					if ( callback ) {
						callback( children[ 0 ] )
					}
				}
			});
		});

		$(thiz.$el).find('.carousel-control-next').click( function() {
			var carousels = $(this).attr("data-targets");
			carousels.split( " " ).forEach( function( carousel ) {
				var name = carousel
				carousel = $( thiz.$el ).find( carousel );
				var inner = carousel.find( ".carousel-inner" );
				carousel_reset( carousel, inner );

				var index = carousel.attr( "data-index" );
				var max_index = carousel.attr( "data-max-index" ) - 1;
				if ( max_index > 0 ) {
					var children = carousel.find(".carousel-inner .carousel-item");
					var first = $( children[0] );
					if ( index < max_index ) {
						index++;
					} else {
						index = 0;
					}
					console.log( "Carousel", name, "going to", index )
					carousel.attr( "data-index", index );
					inner.removeClass( "switching" );
					carousel.find( ".carousel-item" ).css( "transform", "translateX(-100%)" );
					carousel.find( ".carousel-indicators li" ).removeClass( "active" );
					$( carousel.find( ".carousel-indicators li" )[index] ).addClass( "active" );
					var children = carousel.find(".carousel-inner .carousel-item");
					$( children ).removeClass( "active" );
					$( children[ 2 ] ).addClass( "active" );
					if ( callback ) {
						callback( children[ 1 ] )
					}
					var timerid = setTimeout( function() {
						first.detach();
						inner.append( first );
						inner.addClass( "switching" );
						carousel.find( ".carousel-item" ).css( "transform", "translateX(0%)" );
						first[0].offsetHeight;
						inner.removeClass( "switching" );
					}, parseInt( carousel.data( "speed" ) || 350 ) );
					carousel.data( "timer-id", timerid );
					carousel.data( "first", first );
				}
			});
		});
	}
}
