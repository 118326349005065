<template>
	<div class="tab-widget">
		<div class="tab-widget-buttons-wrapper desktop">
			<button v-for="( salon, index ) in salons" :key="'btn-' + index" :id="'salons-btn-' + index" :class="'btn btn-primary tab-widget-button' + ( ( index == ( $route.query.idx || 0 ) ) ? ' active' : '')" v-on:click="switchSalon( index )" type="button">
				<span class="anim-underline">{{ salon.name }}</span>
			</button>
		</div>
		<div v-show="salons.length <= 2" class="tab-widget-buttons-wrapper mobile">
			<button v-for="( salon, index ) in salons" :key="'btn-' + index" :id="'salons-btn-' + index" :class="'btn btn-primary tab-widget-button' + ( ( index == ( $route.query.idx || 0 ) ) ? ' active' : '')" v-on:click="switchSalon( index )" type="button">
				<span class="anim-underline">{{ salon.name }}</span>
			</button>
		</div>
		<div id="block-tabs" v-show="salons.length > 2" class="mobile slider carousel slide2" data-index="0" data-interval="0" :data-max-index="salons.length">
			<div class="carousel-inner">
				<button v-for="( salon, index ) in salons" :key="'btn-' + index" :id="'salons-btn-' + index" :class="'btn btn-primary tab-widget-button carousel-item' + ( ( index == ( $route.query.idx || 0 ) ) ? ' active' : '')" v-on:click="switchSalon( index )" type="button">
					<span class="anim-underline">{{ salon.name }}</span>
				</button>
			</div>
			<div class="carousel-control-prev" data-slide="prev" data-targets="#block-tabs">
				<div></div>
			</div>
			<div class="carousel-control-next" data-slide="next" data-targets="#block-tabs">
				<div></div>
			</div>
		</div>
		<div class="tab-widget-content-wrapper">
			<div class="tab-widget-content card">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-md-3">
							<div class="card border-left-primary card-body color-infocard" @click="appointmentsHistory = 'day'">
								<div class="card-title text-primary">{{$t("common.appday")}}</div>
								<div class="card-text">{{ salon.appointments.day }}</div>
							</div>
						</div>
						<div class="col-sm-6 col-md-3">
							<div class="card border-left-primary card-body color-infocard" @click="appointmentsHistory = 'month'">
								<div class="card-title text-primary">{{$t("common.appmonth")}}</div>
								<div class="card-text">{{salon.appointments.month }}</div>
							</div>
						</div>
						<div class="col-sm-6 col-md-3">
							<div class="card border-left-tertiary card-body color-infocard" @click="appointmentsHistory = 'year'">
								<div class="card-title text-tertiary">{{$t("common.appyear")}}</div>
								<div class="card-text">{{ salon.appointments.year }}</div>
							</div>
						</div>
						<div class="col-sm-6 col-md-3">
							<router-link class="card border-left-quaternary card-body color-infocard" :to="'/userslist?salonid=' + currentSalonId">
								<div class="card-title text-quaternary">{{$t("common.registered")}}</div>
								<div class="card-text">{{ salon.totalClients }}</div>
							</router-link>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div id="old-appointments" class="card">
								<h2 class="card-header">{{$t("common.lactivities")}}</h2>
								<div class="card-body">
									<NotificationsList
										ref="notifs"
										:full="true"
										:max="50"
										:salonid="currentSalonId"
									/>
									<!--div class="logs-container">
										<ul class="logs">
											<li v-for="notif in notifs" :key="notif.id">
											{{ $d( new Date(notif.date), "numeric" ) }} : 
											<i18n :path="`data.notifications['${notif.messageid}'].message`">
												<template v-slot:date>
												</template>
												<template v-if="notif.worker" v-slot:worker>
													<router-link :to="'/worker?id=' + notif.workerid">{{ notif.worker.fname + " " + ( notif.worker.lname || "" ).substr( 0, 1 ).toUpperCase() }}</router-link>
												</template>
												<template v-if="notif.destworker" v-slot:destworker>
													<router-link :to="'/worker?id=' + notif.entryid">{{ notif.destworker.fname + " " + ( notif.destworker.lname || "" ).substr( 0, 1 ).toUpperCase() }}</router-link>
												</template>
												<template v-slot:client>
													<router-link v-if="notif.user" :to="'/user?id=' + notif.userid">{{ notif.user.lname + " " + notif.user.fname }}</router-link>
													<span v-else><i>{{ $tc('common.anonymous') }}</i></span>
												</template>
												<template v-slot:action>
													<b>{{ $t(`data.notifications['${notif.messageid}'].action`) }}</b>
												</template>
												<template v-if="notif.appointment" v-slot:appdate>
													<router-link :to="'/appointments?id=' + notif.entryid"><b>{{ $d( new Date( notif.appointment.date ), "longlong" ) }}</b></router-link>
												</template>
											</i18n>
											</li>
										</ul>
										<div class="logs-expand" v-on:click="jQuery($event.target).prev().toggleClass( 'expanded' ); if ( jQuery($event.target).prev().hasClass( 'expanded' ) ) { jQuery($event.target).text( $t('common.seeless') ) } else { jQuery($event.target).text( $t('common.seeall') ) }">{{$t("common.seeall")}}</div>
									</div-->
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card card-holiday">
								<h2 class="card-header">{{$t("common.holiday")}} / {{$t('common.temporarycloses')}}</h2>
								<div class="card-body">
									<ul class = "holiday" v-if="absences.length">
										<li v-for="absence in absences" :key="absence.id">
											<div class="row">
												<i v-if="absence.worker.username === 'general'">
													{{ $t('common.salontemporaryclose') }}
												</i>
												<component
													:is="isForeignSalon ? 'span' : 'router-link'"
													:to="'/worker?id=' + absence.worker.id"
													v-else
												>
													{{absence.worker.fname + ( absence.worker.lname ? ( " " + absence.worker.lname.substr(0,1).toUpperCase() ) : "" ) }}
												</component>
												<span class="date-hol">
													Du {{absence.start}} Au {{absence.end}}
												</span>
												<span class="message-hol">{{absence.message}}</span>
											</div>
										</li>
									</ul>
									<div class="no-data-hint" v-if="!absences.length">{{ $t('common.noholiday') }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="salon-add-wrapper">
				<button id="salon-add" class="btn btn-secondary" @click="addingSalon = true">
					{{ $t('salons.add') }}
				</button>
			</div>
		</div>
		<AppointmentsHistory v-if="appointmentsHistory" :salonid="currentSalonId" :mode="appointmentsHistory" @close="appointmentsHistory = null"/>
		<Modal v-if="addingSalon" ref="addSalon" name="addSalon" type="small">
			<template v-slot:header>
				<h1 class="title-search">{{$t("salons.addtitle")}}</h1>
			</template>
			<template v-slot:content> <!-- TODO : i18n -->
				<div class="input-group form-group">
					<input class="form-control" v-model="newSalon.clientid" placeholder="Identifiant salon"/>
				</div>
				<div class="input-group form-group">
					<input class="form-control" v-model="newSalon.multicode" placeholder="Code multi-salon"/>
				</div>
			</template>
			<template v-slot:buttons>
				<StatusButton id="validate" type="primary" alert="modal" @click="addSalon( $event )"><i class="fas fa-check"></i></StatusButton>
				<button id="cancel" class="btn btn-gray" @click="newSalon.clientid = ''; newSalon.multicode = ''; addingSalon = false" type="button">{{$t( /*dataChanged ?"common.cancel" :*/"common.close")}}</button>
			</template>
		</Modal>
	</div>
</template>



<script>
	import NotificationsList from "../components/NotificationsList.vue"
	import StatusButton from "../components/StatusButton.vue"
	import Modal from "../components/Modal.vue"
	import AppointmentsHistory from '../modals/AppointmentsHistory.vue'
	import dayjs from "dayjs"
	import api from "../apiClient"
	import carousel from '../components/carousel.js'
		
	export default {
		components: {
			NotificationsList,
			StatusButton,
			Modal,
			AppointmentsHistory
		},
		data() {
			return {
				'jQuery': $,
				addingSalon: false,
				newSalon: {
					clientid: "",
					multicode: "",
				},
				salons: [],
				salon: {
					appointments: {
						day: "0",
						month: 0,
						year: 0
					},
					totalClients: 0
				},
				notifs: [],
				absences: [],
				appointmentsHistory: null
			}
		},
		computed: {
			currentSalonId() {
				if ( !this.salons || this.salons.length == 0 ) {
					return this.$store.state.salon.id
				}
				return this.salons[this.$route.query.idx || 0].id
			},
			isForeignSalon() {
				return this.currentSalonId != this.$store.state.salon.id
			}
		},
		methods: {
			async init() {
				if ( this.$store.state.salon.subsalons && this.$store.state.salon.subsalons.length > 0 ) {
					await this.$api.salons.get({ "id[in]": this.$store.state.salon.subsalons }).then( response => {
						this.salons = [ this.$store.state.salon, ...response.data ]
					}).catch( error => {
						console.log( error )
					})
				} else {
						this.salons = [ this.$store.state.salon ]
				}
				this.update()
			},
			async addSalon( callback ) {
				try {
					if ( ![ "multi" ].includes( this.$store.state.salon.subscription ) ) {
						throw { message: "badsubscription" }
					}
					await this.$api.post( `/salons/${this.$store.state.salon.id}/subsalons`, this.newSalon )
					this.init()
					callback( true )
					setTimeout ( () => {
						this.newSalon.clientid = ""
						this.newSalon.multicode = ""
						this.addingSalon = false
					}, 500 )
				} catch ( error ) {
					console.log( error )
					const m = error.response ? error.response.data.error : error.message
					const tr = this.$t( "errors." + m )
					callback( false, tr == "errors." + m ? m : tr )
				}
			},
			switchSalon( index ) {
				if ( index != ( this.$route.query.idx || 0 ) ) {
					this.$router.push( "/salons?idx=" + index )
				}
			},
			updateStats() {
				var day = new Date()
				day.setSeconds( 0 )
				day.setMinutes( 0 )
				day.setHours( 0 )
				var now = new Date()
				var month = new Date()
				month.setSeconds( 0 )
				month.setMinutes( 0 )
				month.setHours( 0 )
				month.setDate( 1 )
				var year = new Date( month )
				year.setMonth( 0 )

				this.$api.salonsusers.count({ salonid: this.currentSalonId, unregistration: "" }).then( response => {
					this.salon.totalClients = response.data[0].count
				}).catch(error => console.log(error))
				
				this.$api.appointments.count({ salonid: this.currentSalonId, "status[~*]": "finished", "date[gte]": dayjs(day).format("YYYY-MM-DD"), "date[lte]": dayjs(now).format("YYYY-MM-DD 23:59:59") }).then( response => {
				this.salon.appointments.day = response.data[0].count
				}).catch(error => console.log(error))
				
				this.$api.appointments.count({ salonid: this.currentSalonId, "status[~*]": "finished", "date[gte]": dayjs(month).format("YYYY-MM-DD"), "date[lte]": dayjs(now).format("YYYY-MM-DD 23:59:59") }).then( response => {
					this.salon.appointments.month = response.data[0].count
				}).catch(error => console.log(error))

				this.$api.appointments.count({ salonid: this.currentSalonId, "status[~*]": "finished", "date[gte]": dayjs(year).format("YYYY-MM-DD"), "date[lte]": dayjs(now).format("YYYY-MM-DD 23:59:59") }).then( response => {
					this.salon.appointments.year = response.data[0].count
				}).catch(error => console.log(error))
			},
			update() {
				this.updateStats()
				this.$api.absences.get({ salonid: this.currentSalonId }).then(response => {
					var absences = response.data
					absences.forEach(element => {
						element.worker = this.$store.getters.getWorker(element.worker)
						if ( new Date(element.start).getHours() == 0 && new Date(element.start).getMinutes() == 0 ) {
							element.start = dayjs(element.start).format( "D MMM YYYY" )
						} else {
							element.start = dayjs(element.start).format( "D MMM YYYY HH:mm" )
						}
						if ( new Date(element.end).getHours() == 0 && new Date(element.end).getMinutes() == 0 ) {
							element.end = dayjs(element.end).format( "D MMM YYYY" )
						} else {
							element.end = dayjs(element.end).format( "D MMM YYYY HH:mm" )
						}
					})
					this.absences = absences
				}).catch(error => console.log(error))
				/*
				this.$api.notifications.get({ salonid: this.scurrentSalonId }).then( async response => {
					var notifs = response.data.sort( ( a, b ) => dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1 )
					await Promise.all( notifs.map( async notif => {
						notif.worker = this.$store.getters.getWorker(notif.workerid)
						if ( notif.userid ) {
							await this.$store.getters.getUser( notif.userid ).then( resp => {
								notif.user = resp.data[0]
							}).catch( error => console.log( error ) )
						}
						if ( notif.endpoint && notif.entryid ) {
							if ( notif.endpoint.slice( -1 ) === "s" ) {
								notif.endpoint = notif.endpoint.match( /(.+)s/ )[1]
							}
							this.$api[notif.endpoint] && await this.$api[notif.endpoint].get( notif.entryid ).then( resp => {
								let endpoint = notif.endpoint
								if ( endpoint === "worker" ) {
									endpoint = "destworker"
								}
								notif[endpoint] = resp.data[0]
							}).catch( error => console.log( error ) )
						}
					}))
					this.notifs = notifs
				}).catch(error => console.log(error))
				*/
			},
			moveMobileCarousel( force ) {
				if ( window.innerWidth >= 768 || force ) {
					let index = ( this.$route.query.idx || 0 )
					// Don't do anything if current path has no corresponding route (probably a typo from user)
					if ( index >= 0 && index < this.salons.length ) {
						let overflow = 0
						let inner = $(this.$el).find( '.carousel-inner' )
						if ( index == 0 ) {
							let last = $( inner.children()[ inner.children().length - 1 ] )
							last.detach()
							inner.prepend( last )
						} else {
							while ( index > 1 ) {
								let first = $( inner.children()[0] )
								first.detach()
								inner.append( first )
								index--
							}
						}
					}
				}
			}
		},
		watch: {
			'$route.query.idx'() {
				this.update()
				this.moveMobileCarousel()
			}
		},
		async created() {
			await this.init()
		},
		mounted() {
			var thiz = this
			carousel.install( this )

			$(this.$el).find( '.carousel-control-prev' ).click( function() {
				$(thiz.$el).find( '.carousel-inner' ).children().find( ".anim-underline" ).removeClass( "hover" )
				$(thiz.$el).find( '.carousel-inner' ).children()[1].dispatchEvent( new Event( "click" ) )
			})
			$(this.$el).find( '.carousel-control-prev' ).hover( function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[0] ).find( ".anim-underline" ).addClass( "hover" )
			}, function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[0] ).find( ".anim-underline" ).removeClass( "hover" )
			})

			$(this.$el).find( '.carousel-control-next' ).click( function() {
				$(thiz.$el).find( '.carousel-inner' ).children().find( ".anim-underline" ).removeClass( "hover" )
				$(thiz.$el).find( '.carousel-inner' ).children()[2].dispatchEvent( new Event( "click" ) )
			})
			$(this.$el).find( '.carousel-control-next' ).hover( function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[2] ).find( ".anim-underline" ).addClass( "hover" )
			}, function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[2] ).find( ".anim-underline" ).removeClass( "hover" )
			})

			this.moveMobileCarousel( true )
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/salons.scss"/>
